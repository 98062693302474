<template>
    <div class="columns">
        <Permissions :permissions="['ADVERTISERS.LIST.ALL', 'ADVERTISERS.LIST.OWN', 'ADVERTISERS.LIST.UNBIND']">
            <div class="column is-4">
                <AdvertisersTopCard></AdvertisersTopCard>
            </div>
        </Permissions>
        <Permissions :permissions="['WEBMASTERS.LIST.ALL', 'WEBMASTERS.LIST.OWN', 'WEBMASTERS.LIST.UNBIND']">
            <div class="column is-4">
                <WebmastersTopCard></WebmastersTopCard>
            </div>
        </Permissions>
        <Permissions :permissions="['SUPER.PERMISSION']">
            <div class="column is-4">
                <OffersTopCard></OffersTopCard>
            </div>
        </Permissions>
    </div>
</template>

<script>
  import Permissions from "@/components/Common/Permissions";
  import AdvertisersTopCard from "@/components/Admin/Dashboard/DashboardTops/AdvertisersTopCard";
  import OffersTopCard from "@/components/Admin/Dashboard/DashboardTops/OffersTopCard";
  import WebmastersTopCard from "@/components/Admin/Dashboard/DashboardTops/WebmastersTopCard";
  
  export default {
    name: "DashboardTops",
    components: {
      OffersTopCard,
      WebmastersTopCard,
      AdvertisersTopCard,
      Permissions
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .card {
            .card-header {
                .card-header-title {
                    padding: 20px;
                }
            }
            
            .card-content {
                padding: 0;
                background: linear-gradient(180deg, transparent, #ECEFFE 40%);
            }
        }
        
        .custom-header-padding {
            padding: 0 20px 0;
            margin-bottom: 0 !important;
        }
        
        .no-padding {
            padding-bottom: 0 !important;
            
            .tab-content {
                padding: 0 !important;
            }
        }
        
        .clearTable {
            display: inline-table;
            
            td {
                font-size: inherit;
                
                background: transparent !important;
                border-bottom: none !important;
                
                &:first-child {
                    width: 43px;
                    text-align: right;
                }
            }
        }

        .top-card {
            &-title {
                color: #7D8DA0;
            }

            &-count {
                color: #97ADFF;
            }
        }

        .table-wrapper {
            /*background: #F6F7FF;*/
            overflow-x: auto !important;

            tbody {
                background: transparent;
            }

            thead {
                tr {
                    th {
                        background: transparent;
                    }
                }
            }

            tr {
                &:first-of-type {
                    td:first-child {
                        border-top-left-radius: 0;
                    }
                }

                &:last-child {
                    td:first-child {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: $radius-large;
                    }

                    td:last-child {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: $radius-large;
                    }
                }

                &.is-empty {
                    td {
                        border-bottom: 1px solid white;

                        &:after {
                            background-color: white;
                        }

                        &:first-child {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                }

                th {
                    position: static!important;

                    &:first-child {
                        background: #ECEFFE;
                    }
                }

                td {
                    border-bottom: 1px solid white;

                    &:after {
                        background-color: white;
                    }

                    &:first-child {
                        background: #ECEFFE;
                    }
                }
            }
        }
    }
</style>
