import colorsPalette from "../../scss/_colors.scss";
import Color from "color";

// noinspection JSUnresolvedVariable
const colors = {
  light: colorsPalette.light,
  success: colorsPalette.success,
  warning: colorsPalette.warning,
  danger: colorsPalette.danger,
  info: colorsPalette.info
};

export const chartColors = {
  default: {
    ...colors
  }
};

const ctx = document.createElement("canvas").getContext("2d");

for (const [name, color] of Object.entries(colors)) {
  const gradient = ctx.createLinearGradient(0, 0, 0, 449);
  
  gradient.addColorStop(0, Color(color).alpha(0.4).string());
  gradient.addColorStop(1, "rgba(255, 255, 255, 0.05)");
  chartColors.default[`${ name }Fill`] = gradient;
}

const scalesAxesParams = {
  barPercentage: 1.6,
  gridLines: {
    drawBorder: false,
    color: "rgba(29,140,248,0.1)",
    zeroLineColor: "rgba(29,140,248,0.1)"
  },
  ticks: {
    padding: 20,
    precision: 0,
    fontColor: "#9a9a9a"
  }
};

// noinspection JSUnresolvedVariable
export const baseChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
    labels: {
      boxWidth: 8,
      padding: 14,
      usePointStyle: true
    }
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "index",
    intersect: false,
    position: "average"
  },
  scales: {
    yAxes: [scalesAxesParams],
    xAxes: [{ scalesAxesParams }]
  }
};
