<template>
    <div class="card">
        <div
            v-if="icon"
            class="icon">
            <FSvgIcon :icon="icon"></FSvgIcon>
        </div>

        <div class="card__inner">
            <div class="value">
                <slot name="value">
                    <div
                        :key="item.currency"
                        v-for="item of data.new">
                        {{ item.value }} ({{ currencySign(item.currency) }})
                    </div>
                </slot>
            </div>

            <div class="difference">
                <slot name="difference">
                    <FDifferenceIndicator
                        :key="item.currency"
                        v-for="(item, index) of data.new"
                        :value="getDifference(index)">
                        <template #value="{ color }">
                            <span :style="`color: ${color};`">
                                {{ getPreparedDifference(item, index) }} ({{ currencySign(item.currency) }})
                            </span>
                        </template>
                    </FDifferenceIndicator>
                </slot>
            </div>
        </div>

        <div class="label">
            <slot name="label">
                {{ label }}
            </slot>
        </div>
    </div>
</template>

<script>
  import { getPercentDifference, currencySign } from "@core/filters";
  import FDifferenceIndicator from "@/components/Common/FDifferenceIndicator";
  import FSvgIcon from "@/components/Common/FSvgIcon";

  export default {
    name: "FStatisticCard",
    components: {
      FSvgIcon,
      FDifferenceIndicator
    },
    props: {
      data: {
        type: Object,
        default: () => ({
          old: null,
          new: null
        })
      },

      icon: {
        type: String,
        default: null
      },

      label: {
        type: String,
        default: null
      },

      loading: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      currencySign,

      getDifference (index) {
        return Number(this.data?.new[index]?.value) - Number(this.data?.old[index]?.value);
      },

      getPreparedDifference (item, index) {
        return `${ getPercentDifference(Number(this.data.old[index]?.value), Number(item.value), 1) } %`;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .card {
        height: 100%;
        padding: 2.5em 1em 1em;
        background: #FFFFFF;
        box-shadow: 0 10px 20px rgba(22, 34, 57, 0.05),
                    inset 0 1px 0 #FE2615;
        border-radius: 4px;
        overflow: auto;

        .icon {
            margin-bottom: 1.5em;
        }

        &__inner {
            @media screen and (max-width: 768px) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 0.5em;
            }
        }

        .value {
            font-size: 1.75em;
            font-weight: 600;
            margin-bottom: 0.5em;

            @media screen and (max-width: 768px) {
                margin-bottom: 0;
                margin-right: 0.5em;
            }
        }

        .difference {
            margin-bottom: 0.5em;

            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
        }

        .label {
            font-size: 0.75em;
            color: #9AA3B4;
        }
    }
</style>
