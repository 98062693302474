const createLi = ({ marker, seriesName, value, dataIndex }, labels, formatters) => {
    const name = labels[seriesName] ?? seriesName;
    const formattedValue = formatters[seriesName]?.(value, dataIndex) ?? value;

    return `
        <li class="chart-tooltip__item">
            ${ marker }
            <span class="chart-tooltip__item-label">${ name }: </span>
            <span class="chart-tooltip__item-value">${ formattedValue }</span>            
        </li>
    `;
};


export const tooltipBuilder = (params = [], labels = {}, formatters = {}) => {
    const items = params.map((item) => createLi(item, labels, formatters));
    const title = params[0]?.name;

    return `
        <div class="chart-tooltip">
            <p class="chart-tooltip__title">${ title }</p>
            <ul class="chart-tooltip__list">
                ${ items.join("") }
            </ul>
        </div>
    `;
};
