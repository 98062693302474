<template>
    <Card
        :loading="isLoading"
        :title="title">
        <TopCardHeader
            :active="activeToday"
            :total="total">
            <template #active="{ value }">
                {{ value }}
                <DifferenceIndicator
                    :tag="difference.wrapper"
                    :type="difference.type"
                    :colorful="false"
                    :value="difference.value">
                </DifferenceIndicator>
            </template>
            <template #filters>
                <slot name="filters"></slot>
            </template>
        </TopCardHeader>
        <div class="columns">
            <b-tabs
                v-model="activeTab"
                destroy-on-hide
                :animated="false"
                class="no-padding column"
                expanded>
                <b-tab-item
                    :key="tabItem.label"
                    v-for="tabItem in tabs"
                    :label="$t(`admin.dashboard.tableCards.advertisers.tabs.amount.${tabItem.label}`)">
                    <TopCardTable
                        :columns="tableColumns"
                        :data="updatedTableData"
                        :amount-type="amountType"
                        :active-tab-index="activeTab">
                        <template #name="{ row }">
                            <UserOutput
                                v-if="type !== 'offers' && row.item.login"
                                :user="row.item">
                            </UserOutput>
                            <OfferOutput
                                v-else-if="type === 'offers' && row.item.name"
                                :offer="row.item">
                            </OfferOutput>
                            <span v-else>
                                {{ formatEmptyString(row.item.id) }}
                            </span>
                        </template>
                        <template #change="{ row }">
                            <table class="clearTable">
                                <tr>
                                    <td>
                                        <span>
                                            {{ getChangeAbsoluteValue(row.change) }}
                                        </span>
                                    </td>
                                    <td>
                                        <b-tag
                                            :type="getChangeTagType(row.change[amountType].absolute)"
                                            class="is-light">
                                            <b-icon
                                                :icon="getChangeTagIcon(row.change[amountType].absolute)"
                                                pack="fas"
                                                size="is-small">
                                            </b-icon>
                                            <span>
                                                {{ row.change[amountType].relative }}%
                                            </span>
                                        </b-tag>
                                    </td>
                                </tr>
                            </table>
                        </template>
                        <template #graph="{ row }">
                            <sparkline
                                width="80"
                                height="30"
                                :indicator-styles="spIndicatorStyles"
                                :tooltip-props="spTooltipProps(6)"
                                :margin="1"
                                :tooltip-styles="spTooltipStyles">
                                <sparklineCurve
                                    :data="dataGraph(row)"
                                    :limit="7"
                                    :styles="spCurveStyles">
                                </sparklineCurve>
                            </sparkline>
                        </template>
                        <template #empty>
                            <slot name="tableEmpty"></slot>
                        </template>
                        <template #footer>
                            <InfiniteLoading
                                v-if="tableData.length && isInfiniteLoading_"
                                @infinite="infiniteHandler">
                            </InfiniteLoading>
                        </template>
                    </TopCardTable>
                </b-tab-item>
            </b-tabs>
        </div>
    </Card>
</template>

<script>
  import DifferenceIndicator from "@/components/Admin/Dashboard/DifferenceIndicator";
  import Card from "@/components/Common/Card";
  import OfferOutput from "@/components/Common/OfferOutput";
  import UserOutput from "@/components/Common/UserOutput";
  import {
    formatEmptyString,
    momentInst,
    formatCurrency
  } from "@core/filters";

  import TopCardHeader from "@/components/Admin/Dashboard/DashboardTops/Common/TopCardHeader";
  import TopCardTable from "@/components/Admin/Dashboard/DashboardTops/Common/TopCardTable";
  import sparkline from "vue-sparklines";
  import InfiniteLoading from "vue-infinite-loading";
  import moment from "moment";

  export default {
    name: "DashboardTopCard",
    components: {
      DifferenceIndicator,
      OfferOutput,
      TopCardHeader,
      UserOutput,
      Card,
      TopCardTable,
      sparkline,
      InfiniteLoading
    },

    props: {
      type: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: null
      },
      total: {
        type: Number,
        default: null
      },
      tableColumns: {
        type: Array,
        required: true
      },
      amountType: {
        type: String,
        default: null
      },
      tableData: {
        type: Array,
        required: true
      },
      activeToday: {
        type: Number,
        required: true
      },
      difference: {
        type: Object,
        default: () => ({})
      },
      isLoading: {
        type: Boolean,
        default: true
      },
      pagination: {
        type: Object,
        default: null
      },
      currency: {
        type: String,
        default: "USD"
      }
    },

    created () {
      this.updatePeriods(this.activeTab);
    },

    data () {
      const now = momentInst();

      return {
        activeTab: 0,
        tabs: [
          {
            label: "day",
            periods: {
              graphPeriod: {
                dateStart: now.clone().startOf("day").subtract(1, "week").toDate(),
                dateEnd: now.clone().toDate()
              },
              currentPeriod: {
                dateStart: now.clone().startOf("day").toDate(),
                dateEnd: now.clone().toDate()
              },
              prevPeriod: {
                dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
                dateEnd: now.clone().subtract(1, "day").toDate()
              }
            }
          },
          {
            label: "week",
            periods: {
              graphPeriod: {
                dateStart: now.clone().startOf("week").subtract(7, "week").toDate(),
                dateEnd: now.clone().toDate()
              },
              currentPeriod: {
                dateStart: now.clone().startOf("week").toDate(),
                dateEnd: now.clone().toDate()
              },
              prevPeriod: {
                dateStart: now.clone().startOf("week").subtract(1, "week").toDate(),
                dateEnd: now.clone().subtract(1, "week").toDate()
              }
            }
          },
          {
            label: "month",
            periods: {
              graphPeriod: {
                dateStart: now.clone().startOf("month").subtract(7, "month").toDate(),
                dateEnd: now.clone().toDate()
              },
              currentPeriod: {
                dateStart: now.clone().startOf("month").toDate(),
                dateEnd: now.clone().toDate()
              },
              prevPeriod: {
                dateStart: now.clone().startOf("month").subtract(1, "month").toDate(),
                dateEnd: now.clone().subtract(1, "month").toDate()
              }
            }
          }
        ],
        activePeriod: "day",
        spCurveStyles: {
          stroke: "#54a5ff"
        },
        spIndicatorStyles: {
          stroke: "#000"
        },
        spTooltipStyles: {
          position: "absolute",
          margin: "-7px 0 0 -150px",
          background: "rgba(0, 0, 0, 0.6)",
          borderRadius: "5px",
          minWidth: "30px",
          padding: "2px 6px",
          color: "#fff",
          fontSize: "13px"
        }
      };
    },

    computed: {
      isInfiniteLoading_ () {
        return this.pagination?.perPage * this.pagination?.page < this.tableData.length;
      },

      updatedTableData () {
        const limit = this.pagination.perPage;
        const offset = this.pagination.page * limit;

        return this.tableData.slice(0, offset);
      }
    },

    methods: {
      formatEmptyString,

      dataGraph (values) {
        return values.dates?.map(item => {
          if (!Number.isInteger(item)) {
            if (values.amountType === "leads") {
              return item.countLeadsCreated;
            }
            return item.moneyAdvertiserWrittenOff - item.moneyWebmasterAccrued;
          }
          return item;
        });
      },

      spTooltipProps (amount) {
        const period = this.activePeriod;

        return {
          formatter (value) {
            // Показывает дату в тултипе, исходя из периода
            if (period === "week") {
              return `${ moment()
                .clone()
                .startOf(period)
                .add(1, "day")
                .subtract(amount - value.index, period)
                .format("YYYY-MM-DD") }：${ value.value }`;
            } else {
              return `${ moment()
                .clone()
                .startOf(period)
                .subtract(amount - value.index, period)
                .format("YYYY-MM-DD") }：${ value.value }`;
            }
          }
        };
      },

      infiniteHandler ($state) {
        this.$emit("update:pagination", {
          page: this.pagination.page + 1,
          perPage: this.pagination.perPage
        });
        this.$emit("update");
        $state.loaded();
      },

      processPeriods (tab) {
        const {
          label,
          periods
        } = tab;
        /*   const targetMonthBigger = currentPeriod.dateEnd.daysInMonth() > prevPeriod.dateEnd.daysInMonth();

            if (label === "month" && targetMonthBigger) {
              prevPeriod.dateEnd.endOf("month");
            }*/

        return {
          label,
          currentPeriod: periods.currentPeriod,
          graphPeriod: periods.graphPeriod,
          prevPeriod: periods.prevPeriod
        };
      },

      updatePeriods (tabIndex) {
        const payload = this.processPeriods(this.tabs[tabIndex]);

        this.activePeriod = payload.label;
        this.$emit("update-periods", payload);
      },

      getChangeAbsoluteValue (change) {
        if (this.amountType === "commission") {
          return formatCurrency(change[this.amountType].absolute, this.currency);
        } else {
          return formatEmptyString(change[this.amountType].absolute);
        }
      },

      getChangeTagType (value) {
        if (value > 0) {
          return "is-success";
        } else if (value < 0) {
          return "is-danger";
        } else {
          return "";
        }
      },

      getChangeTagIcon (value) {
        if (value > 0) {
          return "caret-up";
        } else if (value < 0) {
          return "caret-down";
        } else {
          return "minus";
        }
      }
    },

    watch: {
      activeTab (tabIndex) {
        this.updatePeriods(tabIndex);
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
      g line {
          display: none;
      }

      .table-wrapper {
          max-height: 628px;
      }
  }
</style>
