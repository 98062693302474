<template>
    <section>
        <Card>
            <div class="columns is-vcentered is-multiline">
                <div class="column is-2">
                    <FSelect
                        v-model="filters.groups"
                        :clearable="false"
                        :get-data="getGroupingTypes"
                        :placeholder="$t(`admin.dashboard.filters.labels.groups`)"
                        :searchable="false"
                        field="label"
                        prop="value">
                    </FSelect>
                </div>

                <div class="column is-narrow">
                    <CurrencyCheckbox v-model="filters.currency"></CurrencyCheckbox>
                </div>

                <div class="column is-flex is-justify-content-flex-end">
                    <CustomDatepicker v-model="filters.datepicker"></CustomDatepicker>
                </div>

                <div class="column is-narrow">
                    <b-field>
                        <b-checkbox-button
                            v-model="isOptionsOpen"
                            type="is-info is-light">
                            {{ $t(`admin.dashboard.filters.labels.filters`) }}
                            <b-tag
                                rounded
                                type="is-info ml-1">
                                {{ optionFiltersCount }}
                            </b-tag>
                        </b-checkbox-button>
                    </b-field>
                </div>
            </div>
        </Card>

        <DashboardFiltersOptions
            v-show="isOptionsOpen"
            :filters.sync="filters">
        </DashboardFiltersOptions>
    </section>
</template>

<script>
  import Card from "@/components/Common/Card";
  import CurrencyCheckbox from "@/components/Common/CurrencyCheckbox";
  import { Filters, OffersFilters, UsersFilters, FiltersCount } from "@core/mixins";
  import CustomDatepicker from "@/components/Common/CustomDatepicker";
  import FSelect from "@/components/Common/FSelect";
  import DashboardFiltersOptions from "@/components/Admin/Dashboard/DashboardFiltersOptions";

  export default {
    name: "DashboardFilters",
    mixins: [Filters, UsersFilters, OffersFilters, FiltersCount],
    components: {
      CustomDatepicker,
      FSelect,
      DashboardFiltersOptions,
      Card,
      CurrencyCheckbox
    },

    data () {
      return {
        isOptionsOpen: false
      };
    },

    computed: {
      optionFilters () {
        const {
          datepicker,
          groups,
          currency,
          ...optionFilters
        } = this.filters;

        return optionFilters;
      }
    },
    methods: {
      getGroupingTypes () {
        const groups = [
          {
            value: "day",
            label: this.$t("admin.dashboard.filters.values.groups.day")
          },
          {
            value: "week",
            label: this.$t("admin.dashboard.filters.values.groups.week")
          },
          {
            value: "month",
            label: this.$t("admin.dashboard.filters.values.groups.month")
          },
          {
            value: "year",
            label: this.$t("admin.dashboard.filters.values.groups.year")
          }
        ];
        return {
          items: groups,
          count: groups.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
    section {
        margin-bottom: 1.5em;

        .columns {
            align-items: flex-end;
        }
    }
</style>
