<template>
    <v-chart
        :key="JSON.stringify(datasets)"
        :autoresize="isAutoresize"
        :loading="isLoading"
        :option="option"
        class="chart"></v-chart>
</template>

<script>
  import { defaultLineStyle, defaultOptions } from "@/components/Charts/echart.config";
  import { BarChart, LineChart } from "echarts/charts";
  import { use } from "echarts/core";
  import _cloneDeep from "lodash/cloneDeep";
  
  import _merge from "lodash/merge";
  
  import { THEME_KEY } from "vue-echarts";
  
  use([
    BarChart,
    LineChart
  ]);
  
  export default {
    name: "DashboardChartTemplate",
  
    props: {
      datasets: {
        type: Array,
        default: () => []
      },
  
      xLabels: {
        type: Array,
        default: () => []
      },
  
      isLoading: {
        type: Boolean,
        default: false
      },

      tooltipFormatter: {
        type: Function,
        default: null
      },

      customConfig: {
        type: Object,
        default: () => ({})
      }
    },
    
    provide: {
      [THEME_KEY]: "light"
    },
    
    computed: {
      option () {
        return _merge(
          _cloneDeep(defaultOptions),
          {
            legend: {
              data: this.datasets.filter(({ name }) => name != null).map(({ name }) => name),
              formatter: (legendName) => this.datasets.find(({ name }) => name === legendName)?.label,
              left: "center"
            },
            
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: this.xLabels
            },
            
            yAxis: {
              type: "value"
            },

            tooltip: {
              // TODO: заменить на richText когда PR будет принят
              // https://github.com/apache/echarts/pull/14994
              renderMode: "html",
              formatter: this.tooltipFormatter
            },
            
            series: this.datasets.map(({ name, data, color, backgroundColor }, index, array) => {
              const degrees = 360 / array.length * index;

              return {
                ...defaultLineStyle,
                name,
                data,
                emphasis: {
                  focus: "series"
                },
                color: color ?? `hsla(${ degrees }deg, 70%, 65%, 1)`
              };
            })
          },
          this.customConfig
        );
      },

      isAutoresize () {
        // echarts bug
        return this.datasets.length > 0;
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
