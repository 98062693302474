<template>
    <Card
        :class="type"
        :title="title">
        <div class="columns divider is-multiline">
            <slot
                :new="data.new"
                :old="data.old">
                <div class="column nowrap is-flex-grow-1">
                    <h5 class="subtitle has-text-centered is-5">
                        <b-skeleton
                            v-if="data.new == null"
                            size="is-large skeleton-centered"
                            width="60%">
                        </b-skeleton>
                        <slot
                            v-else
                            :new="data.new"
                            :old="data.old"
                            name="left">
                        </slot>
                    </h5>
                </div>
                <div class="column nowrap is-flex-grow-1">
                    <h5 class="subtitle has-text-centered is-5">
                        <b-skeleton
                            v-if="data.new == null"
                            size="is-large skeleton-centered"
                            width="60%">
                        </b-skeleton>
                        <slot
                            v-else
                            :new="data.new"
                            :old="data.old"
                            name="right">
                        </slot>
                    </h5>
                </div>
            </slot>
        </div>
    </Card>
</template>

<script>
  import { formatPercent, toFixed } from "@core/filters";
  import Card from "@/components/Common/Card";
  
  export default {
    name: "DifferenceCard",
    components: { Card },
    props: {
      data: {
        type: Object,
        default: null
      },
      type: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: ""
      }
    },
    computed: {
      isSlot () {
        return !!this.$scopedSlots.additionalData;
      }
    },
    methods: {
      toFixed,
      getPercentage (yesterday, today) {
        if (this.percents) return toFixed(formatPercent(Math.abs(yesterday - today)), 2);

        if (yesterday === today) return 0;
        else if (yesterday === 0 || today === 0) return 100;
        else if (yesterday && today) return formatPercent(Math.abs(yesterday - today) / yesterday).toFixed(2);
      }
    }
  };
</script>

<style lang="scss" scoped>
.skeleton-centered::v-deep {
    .b-skeleton-item {
        margin: auto;
    }
}

.additional-data-divider {
    position: relative;
    width: 100%;

    &:after {
        content: "";
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        width: 75%;
        margin: auto;
        height: 1px;
        background: #ededed;
    }
}

.divider {
    //noinspection SassScssUnresolvedMixin
    @include tablet {
        .column {
            position: relative;

            &:not(:last-child) {
                &:after {
                    content: "";
                    top: 0;
                    right: 0;
                    width: 1px;
                    bottom: 0;
                    height: 75%;
                    margin: auto;
                    position: absolute;
                    background: #EDEDED;
                }
            }
        }
    }
    
    //noinspection SassScssUnresolvedMixin
    @include mobile {
        .column {
            position: relative;
            
            &:not(:last-child) {
                &:after {
                    content: "";
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: absolute;
                    width: 75%;
                    margin: auto;
                    height: 1px;
                    background: #ededed;
                }
            }
        }
    }
}

.card {
    ::v-deep {
        .card-header {
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);

            .card-header-title {
                text-align: center;
                padding: 10px;
                justify-content: center;
            }
        }

        .card-content {
            padding: 10px;
        }
    }
}

.leads {
    ::v-deep {
        .card-header {
            background: #ecf6ff;

            .card-header-title {
                color: #1a55af;
            }
        }
    }
}

.approve {
    ::v-deep {
        .card-header {
            background: #f6f4ff;

            .card-header-title {
                color: #5843be;
            }
        }
    }
}

.commission {
    ::v-deep {
        .card-header {
            background: #fffbf0;

            .card-header-title {
                color: #427a0a;
            }
        }
    }
}

.debit {
    ::v-deep {
        .card-header {
            background: #ffece6;

            .card-header-title {
                color: #fa5f1c;
            }
        }
    }
}

.prepaid {
    ::v-deep {
        .card-header {
            background: #fdff9e;

            .card-header-title {
                color: #cf9c38;
            }
        }
    }
}

.cashFlow {
    ::v-deep {
        .card-header {
            background: #e3f8ef;

            .card-header-title {
                color: #00855B;
            }
        }
    }
}
</style>
