<template>
    <div class="columns">
        <div class="column is-12">
            <DashboardDifferenceCards>
            </DashboardDifferenceCards>

            <DashboardFilters
                :filters.sync="filters"
                @clear="clearFilters"
                @update="update">
            </DashboardFilters>
            <Card>
                <div>
                    <DashboardChart
                        :data="statistic"
                        :filters="filters"
                        :user-permissions="userPermissions"
                        :is-loading="isLoading">
                    </DashboardChart>
                </div>
            </Card>
            <DashboardTops></DashboardTops>
        </div>
    </div>
</template>

<script>
  import Card from "@/components/Common/Card";
  import { formatPercent, momentInst, toFixed } from "@core/filters";
  import { momentToDate } from "@core/helpers/momentToDate";
  import { FilteredComponent } from "@core/mixins";
  import { hasPermissions, hasPermissionsStrict } from "@core/mixins/permissions";
  import { GET_OFFER, GET_STATISTIC } from "@core/store/action-constants";
  import momentjs from "moment";
  import { mapActions, mapState } from "vuex";
  import DashboardChart from "@/components/Admin/Dashboard/DashboardChart";
  import DashboardDifferenceCards from "@/components/Admin/Dashboard/DashboardDifferenceCards";
  import DashboardFilters from "@/components/Admin/Dashboard/DashboardFilters";
  import DashboardTops from "@/components/Admin/Dashboard/DashboardTops";
  
  export default {
    name: "Dashboard",
    mixins: [FilteredComponent],
    components: {
      DashboardTops,
      DashboardFilters,
      Card,
      DashboardDifferenceCards,
      DashboardChart
    },
    async beforeCreate () {
      const userPermissions = (this.$store || { getters: {} }).getters.permissions;

      if (userPermissions && !hasPermissions([
        "SUPER.PERMISSION",
        "DASHBOARD.DEBIT.GET",
        "DASHBOARD.PREPAID_EXPENSE.GET",
        "DASHBOARD.COMMISSION.GET",
        "DASHBOARD.CASH_FLOW.GET"
      ], userPermissions)) {
        await this.$router.push({
          name: "admin:statistics"
        }).catch(_ => {});
      }
    },
    
    async created () {
      const {
        offerId,
        groups
      } = this.filters;

      await this.GET_APPROVE_COMPARE();

      if (hasPermissionsStrict(["DASHBOARD.DEBIT.GET"], this.userPermissions)) {
        await this.GET_DEBIT_COMPARE();
      }

      if (hasPermissionsStrict(["DASHBOARD.PREPAID_EXPENSE.GET"], this.userPermissions)) {
        await this.GET_PREPAID_COMPARE();
      }

      if (hasPermissionsStrict(["DASHBOARD.COMMISSION.GET"], this.userPermissions)) {
        await this.GET_COMMISSION_COMPARE();
      }

      await this.GET_STATISTICS_COMPARE({
        offerId,
        groups: [groups?.toUpperCase()]
      });
    },
    data () {
      const {
        dateStart,
        dateEnd,
        groups,
        currency
      } = this.$route.query;
      const preferredCurrency = this.$store.state.auth.preferredCurrency;

      const route = this.$route.query,
            offerId = this.routeFilter(route.offerId),
            advertiserId = this.routeFilter(route.advertiserId),
            webmasterId = this.routeFilter(route.webmasterId),
            managerId = this.routeFilter(route.managerId),
            withdrawType = route.withdrawType;

      return {
        filters: {
          datepicker: {
            dateStart: momentToDate(
              dateStart
                ? momentInst(dateStart).startOf("day")
                : momentInst().startOf("day").subtract(1, "month")
            ),
            dateEnd: momentToDate(momentInst(dateEnd || undefined).endOf("day"))
          },
          withdrawType: withdrawType || null,
          currency: currency || preferredCurrency,
          groups: groups ?? "day",
          offerId: offerId || null,
          webmasterId: webmasterId || null,
          advertiserId: advertiserId || null,
          managerId: managerId || null
        }
      };
    },
    computed: {
      isLoading () {
        return this.$wait(`admin/${ GET_STATISTIC }`);
      },

      ...mapState("admin", {
        statistic: state => {
          const items = state.statistic.statistics?.items;

          return {
            ...state.statistic.statistics,
            items: items ? [...items].reverse() : null
          };
        }
      }),

      userPermissions () {
        return (this.$store || { getters: {} }).getters.permissions;
      }
    },
    methods: {
      toFixed,
      formatPercent,

      ...mapActions("admin", [
        GET_STATISTIC,
        "GET_DEBIT_COMPARE",
        "GET_PREPAID_COMPARE",
        "GET_COMMISSION_COMPARE",
        "GET_STATISTICS_COMPARE",
        "GET_APPROVE_COMPARE",
        GET_OFFER
      ]),

      async update () {
        const {
          groups,
          datepicker,
          ...filters
        } = this.filters;

        const queryGroups = groups.toUpperCase();

        await this.GET_STATISTIC({ filters: { ...datepicker, ...filters }, groups: [queryGroups] });

        const query = {
          ...filters,
          groups,
          dateStart: momentjs(datepicker.dateStart).format("YYYY-MM-DD"),
          dateEnd: momentjs(datepicker.dateEnd).format("YYYY-MM-DD")
        };

        this.$router.push({
          query
        }).catch(_ => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
    @mixin shadow {
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
    }

    .leads-tab {
        ::v-deep.card-header {
            background: #ecf6ff;
            @include shadow;

            .card-header-title {
                padding: 10px;
                justify-content: center;
                color: #1a55af;
            }
        }
    }

    .approve-tab {
        ::v-deep.card-header {
            background: #f6f4ff;
            @include shadow;

            .card-header-title {
                color: #5843be;
            }
        }
    }

    .commission-tab {
        ::v-deep.card-header {
            background: #fffbf0;
            @include shadow;

            .card-header-title {
                color: #427a0a;
            }
        }
    }
</style>
