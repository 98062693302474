<template>
    <el-dropdown @command="onClick">
        <span class="el-dropdown-link">
            {{ label }}
            <i
                :class="icon"
                :style="`color: ${iconColor};`">
            </i>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">
                Action 1
            </el-dropdown-item>
            <el-dropdown-item command="b">
                Action 2
            </el-dropdown-item>
            <el-dropdown-item command="c">
                Action 3
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
  export default {
    name: "FDropdownOptions",
    props: {
      label: {
        type: String,
        default: null
      },
      icon: {
        type: String,
        default: "el-icon-more"
      },
      iconColor: {
        type: String,
        default: "#9AA3B4"
      }
    },
    methods: {
      onClick (command) {
        this.$message("click on item " + command);
      }
    }
  };
</script>

<style lang="scss" scoped>

</style>
