<template>
    <FCard
        title-class="tablet:w-1/3"
        :col-title="12"
        :col-title-xs="8">
        <template #header>
            <div class="flex justify-between flex-wrap py-4 laptop:py-0">
                <div class="text-xl mb-4">
                    <h3 class="title mt-auto">
                        {{ $t('webmaster.dashboard.statistics.title') }}
                    </h3>
                </div>

                <div class=" mb-4">
                    <FDatepicker
                        v-model="localDatepicker"
                        @setPeriod="period = $event">
                    </FDatepicker>
                </div>
            </div>
        </template>
    
        <template>
            <el-row
                :gutter="16"
                type="flex"
                class="flex-wrap">
                <el-col
                    :key="card.label"
                    v-for="card in statisticCards"
                    class="mb-2 tablet:w-1/2 desktop:w-1/4"
                    :sm="6"
                    :xs="12">
                    <FStatisticCard
                        v-loading="isLoading"
                        :icon="card.icon"
                        :label="card.label"
                        :data="card.data">
                        <template #value>
                            <div
                                :key="income.currency"
                                v-for="income of card.data.income">
                                {{ currencyWithSymbol(income) }}
                            </div>
                        </template>
                    </FStatisticCard>
                </el-col>
            </el-row>
        </template>
    
        <template #footer>
            <div class="card__buttons">
                <div class="card__buttons-item w-1/2 desktop:w-auto">
                    <FButton
                        type="primary"
                        plain
                        @click.native="openPayments()">
                        <p class="mobile:hidden laptop:block">
                            {{ $t('webmaster.dashboard.statistics.buttons.payOrder') }}
                        </p>
                        <p class="laptop:hidden">
                            {{ $t('webmaster.dashboard.statistics.buttons.pay') }}
                        </p>
                    </FButton>
                </div>
            </div>
        </template>
    </FCard>
</template>

<script>
  import FCard from "@/components/Common/FCard";
  import FButton from "@/components/Common/FButton";
  import FDatepicker from "@/components/Common/FDatepicker";
  import FStatisticCard from "@/components/Common/FStatisticCard";
  import {
    GET_STATISTIC,
    GET_OLD_STATISTIC
  } from "@core/store/action-constants";
  import { UPDATE_FILTERS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import _cloneDeep from "lodash/cloneDeep";
  import { momentToDate } from "@core/helpers/momentToDate";
  import moment from "moment";
  import { formatCurrency, toFixed } from "@core/filters";

  export default {
    name: "DashboardStatistic",
    components: {
      FStatisticCard,
      FDatepicker,
      FButton,
      FCard
    },
    props: {
      data: {
        type: Object,
        default: () => ({})
      }
    },
    created () {
      this.datepicker = _cloneDeep(this.startDatepicker);
      this.localDatepicker = _cloneDeep(this.startDatepicker);
    },
    data () {
      const localDatepicker = _cloneDeep(this.$store.state.webmaster.dashboard.statistic.filters.datepicker);

      const {
        dateStart,
        dateEnd
      } = this.$route.query;
        
      return {
        startDatepicker: {
          dateStart: momentToDate(moment(dateStart || undefined)),
          dateEnd: momentToDate(moment(dateEnd || undefined))
        },
        localDatepicker,
        period: null,
        isLoading: false
      };
    },
    computed: {
      ...mapState("webmaster/dashboard/statistic", [
        "oldStatistic",
        "newStatistic",
        "currencies"
      ]),
    
      ...mapFields("webmaster/dashboard/statistic", {
        fields: ["datepicker"],
        base: "filters",
        action: UPDATE_FILTERS
      }),
    
      loading () {
        return this.$wait(`webmaster/dashboard/statistic/${ GET_STATISTIC }`);
      },
      
      statisticCards () {
        return [
          {
            label: this.$t("webmaster.dashboard.statistics.cards.labels.transition"),
            icon: "target",
            data: {
              old: this.oldStatistic?.clicks,
              new: this.newStatistic?.clicks
            }
          },
          {
            label: this.$t("webmaster.dashboard.statistics.cards.labels.registration"),
            icon: "user-plus",
            data: {
              old: this.oldStatistic?.confirmed,
              new: this.newStatistic?.leads
            }
          },
          {
            label: this.$t("webmaster.dashboard.statistics.cards.labels.depositAmount"),
            icon: "deposit",
            data: {
              old: this.oldStatistic?.confirmed,
              new: this.newStatistic?.confirmed
            }
          },
          {
            label: this.$t("webmaster.dashboard.statistics.cards.labels.income"),
            icon: "income",
            data: {
              old: this.oldStatistic?.income,
              new: this.newStatistic?.income,
              income: this.newStatistic?.income
            }
          }
        ];
      }
    },
    methods: {
      ...mapActions("webmaster/dashboard/statistic", [
        GET_STATISTIC,
        GET_OLD_STATISTIC
      ]),
    
      openPayments () {
        this.$router.push({ name: "webmaster:finances" });
      },
        
      async update () {
        this.isLoading = true;
        await this.GET_STATISTIC();
        await this.GET_OLD_STATISTIC(this.period);
        this.isLoading = false;

        const { dateStart, dateEnd } = this.localDatepicker;

        const query = {
          dateStart: moment(dateStart).format("YYYY-MM-DD"),
          dateEnd: moment(dateEnd).format("YYYY-MM-DD")
        };

        this.$router.push({
          query
        }).catch(_ => {});
      },

      currencyWithSymbol ({ value, currency }) {
        return formatCurrency(toFixed(value, 2), currency);
      }
    },
    watch: {
      localDatepicker: {
        deep: true,
        async handler (value) {
          this.datepicker = _cloneDeep(value);

          await this.update();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .card {
        &__buttons {
            @apply flex;

            @media screen and (max-width: 350px) {
                @apply flex-wrap;
            }

            &-item  {
                @apply mb-4;

                @media screen and (max-width: 768px) {
                    width: calc(50% - 0.5rem);
                    /*margin-right: 1rem;*/

                    /*&:nth-child(even) {*/
                    /*    margin-right: 0;*/
                    /*}*/
                }

                @media screen and (max-width: 350px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
</style>
