<template>
    <div class="columns is-multiline">
        <template v-for="(card, i) in DashboardDifferenceCards">
            <Permissions
                :key="i"
                :permissions="card.permissions">
                <div class="column is-6 is-4-desktop is-3-fullhd is-2-real-fullhd">
                    <DifferenceCard
                        :data="card.data"
                        :title="$t(`admin.dashboard.differenceCards.${ card.type }`)"
                        :type="card.type">
                        <template #left>
                            <span
                                :key="idx"
                                v-for="(item, idx) in card.data.new">
                                <template v-if="'count' in item">
                                    {{ item.count }}
                                    ({{ currencySign(item.currency) }}) <br>
                                </template>
                                <template v-else>
                                    {{ formatCurrency(item.value, item.currency) }} <br>
                                </template>
                            </span>
                        </template>

                        <template #right>
                            <DifferenceIndicator
                                :key="idx"
                                v-for="(item, idx) in getPercentage(card)"
                                :value="getDifference(card.data)[idx]">
                                <template #value>
                                    {{ `${ item }%` }} <br>
                                </template>
                            </DifferenceIndicator>
                        </template>
                    </DifferenceCard>
                </div>
            </Permissions>
        </template>
    </div>
</template>

<script>
  import Permissions from "@/components/Common/Permissions";
  import { formatCurrency, formatPercent, toFixed, currencySign } from "@core/filters";
  import { mapActions, mapState } from "vuex";
  import DifferenceCard from "@/components/Admin/Dashboard/DifferenceCard";
  import DifferenceIndicator from "@/components/Admin/Dashboard/DifferenceIndicator";
  import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
  import { GET_CURRENCY } from "@core/store/action-constants";
  
  export default {
    name: "DashboardDifferenceCards",
    components: {
      Permissions,
      DifferenceCard,
      DifferenceIndicator
    },

    async created () {
      await this.GET_CURRENCY();
    },

    computed: {
      ...mapState("admin", {
        debitCompare: state => state.statistic.debitCompare,
        prepaidCompare: state => state.statistic.prepaidCompare,
        commissionCompare: state => state.statistic.commissionCompare,
        approveCompare: state => state.statistic.approveCompare,
        statisticToday: state => state.statistic.statisticToday,
        statisticYesterday: state => state.statistic.statisticYesterday
      }),

      ...mapState("admin/currencyList", {
        currencyList: ({ data }) => data
      }),

      cashFlow () {
        const today = this.formatStatisticsData(
          this.statisticToday,
          "value",
          "moneyAdvertiserWrittenOff"
        );
        const yesterday = this.formatStatisticsData(
          this.statisticYesterday,
          "value",
          "moneyAdvertiserWrittenOff"
        );

        return {
          today: this.setDefaultWhenNoElements(today, "value"),
          yesterday: this.setDefaultWhenNoElements(yesterday, "value")
        };
      },

      leads () {
        const today = this.formatStatisticsData(this.statisticToday, "count", "countLeadsCreated");
        const yesterday = this.formatStatisticsData(this.statisticYesterday, "count", "countLeadsCreated");

        return {
          today: this.setDefaultWhenNoElements(today, "count"),
          yesterday: this.setDefaultWhenNoElements(yesterday, "count")
        };
      },

      ...mapState("admin/currencyList", {
        currencyList: ({ data }) => data
      }),

      DashboardDifferenceCards () {
        return [
          {
            type: "leads",
            data: {
              old: sortByPreferredCurrency(this.leads.yesterday),
              new: sortByPreferredCurrency(this.leads.today)
            },
            permissions: ["SUPER.PERMISSION"]
          },
          {
            type: "approve",
            data: {
              old: sortByPreferredCurrency(this.approveCompare?.yesterday),
              new: sortByPreferredCurrency(this.approveCompare?.today)
            },
            permissions: ["SUPER.PERMISSION"]
          },
          {
            type: "debit",
            data: {
              old: sortByPreferredCurrency(this.debitCompare?.yesterday),
              new: sortByPreferredCurrency(this.debitCompare?.today)
            },
            permissions: ["DASHBOARD.DEBIT.GET"]
          },
          {
            type: "prepaid",
            data: {
              old: sortByPreferredCurrency(this.prepaidCompare?.yesterday),
              new: sortByPreferredCurrency(this.prepaidCompare?.today)
            },
            permissions: ["DASHBOARD.PREPAID_EXPENSE.GET"]
          },
          {
            type: "commission",
            data: {
              old: sortByPreferredCurrency(this.commissionCompare?.yesterday),
              new: sortByPreferredCurrency(this.commissionCompare?.today)
            },
            permissions: ["DASHBOARD.COMMISSION.GET"]
          },
          {
            type: "cashFlow",
            data: {
              old: sortByPreferredCurrency(this.cashFlow.yesterday),
              new: sortByPreferredCurrency(this.cashFlow.today)
            },
            permissions: ["DASHBOARD.CASH_FLOW.GET"]
          }
        ];
      }
    },

    methods: {
      toFixed,
      formatCurrency,
      currencySign,

      ...mapActions("admin/currencyList", {
        GET_CURRENCY
      }),

      setDefaultWhenNoElements (array, key) {
        const currency = sortByPreferredCurrency(this.currencyList);
        const defaultCurrency = currency.map(currency => ({ currency, [key]: 0 }));

        if (array !== undefined) {
          return defaultCurrency.map(item => {
            const findElement = array.find(el => el.currency === item.currency);
            if (findElement) {
              return findElement;
            } return item;
          });
        }
      },

      formatStatisticsData (data, key, value) {
        return data?.items?.map((item) => ({ currency: item.currency, [key]: item[value] }));
      },

      setArray (data) {
        return Array.isArray(data) ? data.map(item => item.value ?? item.count) : [data];
      },

      getDifference (data) {
        return this.setArray(data.old).map((oldItem, idx) => {
          return this.setArray(data.new)[idx] - oldItem;
        });
      },
  
      getPercentage (card) {
        const newData = this.setArray(card.data.new);
        const oldData = this.setArray(card.data.old);

        return oldData.map((oldItem, idx) => {
          if (card.percents) {
            return toFixed(formatPercent(Math.abs(oldItem - newData[idx])), 2);
          }
          if (newData[idx] === oldItem) {
            return 0;
          } else if (newData[idx] === 0 || oldItem === 0) {
            return 100;
          } else if (newData[idx] && oldItem) {
            return formatPercent(Math.abs(newData[idx] - oldItem) / newData[idx]).toFixed(2);
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
