<template>
    <div class="wrapper whitespace-nowrap">
        <slot
            :value="value">
            <span>
                <slot
                    :value="value"
                    name="icon">
                    <div>
                        <div
                            v-if="value > 0"
                            class="icon more">
                            <i class="el-icon-d-arrow-left"></i>
                        </div>
                        <div
                            v-if="value === 0"
                            class="icon equally">
                            <i class="el-icon-minus"></i>
                        </div>
                        <div
                            v-if="value < 0"
                            class="icon less">
                            <i class="el-icon-d-arrow-left"></i>
                        </div>
                    </div>
                </slot>
            </span>
            <slot
                :value="value"
                :color="color"
                name="value">
                <span :style="`color: ${color};`">
                    {{ value }}
                </span>
            </slot>
        </slot>
    </div>
</template>

<script>
  export default {
    name: "FDifferenceIndicator",
    props: {
      value: {
        type: Number,
        required: true
      }
    },
    computed: {
      color () {
        if (this.value > 0) {
          return "#1BD12D";
        } else if (this.value < 0) {
          return "#FE2615";
        } else {
          return "#9AA3B4";
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .wrapper {
        font-size: 0.75em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &:not(:first-of-type) {
            padding-top: 0;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12px;
            height: 12px;
            background: #9AA3B4;
            border-radius: 2px;
            color: #FFFFFF;
            margin-right: 4px;

            i {
                font-size: 8px;
            }

            &.more {
                transform: rotate(90deg);
                background: #1BD12D;
            }

            &.less {
                transform: rotate(-90deg);
                background: #FE2615;
            }
        }
    }
</style>