<template>
    <FCard
        min-inner-width="920px"
        :col-title-xs="12"
        filters-class="dashboard-filters">
        <template #title>
            <el-col
                class="mt-4 laptop:mt-0 laptop:w-1/4 desktop:w-1/5 full-hd:w-1/5"
                :xs="$attrs['col-title-xs']">
                <h3 class="title mt-auto text-2xl font-bold">
                    {{ $t('webmaster.dashboard.charts.title') }}
                </h3>
            </el-col>
        </template>
        <template #filters>
            <el-row
                :gutter="16"
                class="flex w-full justify-start tablet:justify-end laptop:justify-start desktop:justify-end">
                <el-col class="w-auto tablet:w-full">
                    <CurrencyCheckbox v-model="filters.currency"></CurrencyCheckbox>
                </el-col>
                <el-col class="w-1/4 tablet:w-full">
                    <FSelect
                        v-model="filters.offerId"
                        :placeholder="$t('webmaster.dashboard.charts.select.labels.offers')"
                        :get-data="getOffers"
                        class="is-fixed'"
                        clearable
                        filterable
                        prop="id">
                        <template #text="{ option }">
                            <OfferOutput :offer="option"></OfferOutput>
                        </template>
                        <template #selected-option="{ option }">
                            <OfferOutput :offer="option"></OfferOutput>
                        </template>
                    </FSelect>
                </el-col>
                <!--                <el-col :span="5">-->
                <!--                    <FSelect-->
                <!--                        v-model="filters.os"-->
                <!--                        :options="filterOptions"-->
                <!--                        placeholder="ОС">-->
                <!--                    </fselect>-->
                <!--                </el-col>-->
                <!--                <el-col :span="5">-->
                <!--                    <FSelect-->
                <!--                        v-model="filters.geo"-->
                <!--                        :options="filterOptions"-->
                <!--                        placeholder="Гео">-->
                <!--                    </fselect>-->
                <!--                </el-col>-->
                <FDatepicker
                    :value="filters.datepicker"
                    class="ml-2"
                    @input="setDateRange">
                </FDatepicker>
            </el-row>
        </template>

        <template>
            <FChartTemplate
                :datasets="datasets"
                :is-loading="isLoading"
                :tooltip-formatter="tooltipFormatter"
                :x-labels="xLabels">
            </FChartTemplate>
        </template>
    </FCard>
</template>

<script>
  import FCard from "@/components/Common/FCard";
  import FSelect from "@/components/Common/FSelect";
  import FDatepicker from "@/components/Common/FDatepicker";
  import FChartTemplate from "@/components/Common/FChartTemplate";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import { chartColors } from "@/components/Charts/chart.config";
  import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
  import { FilteredComponent } from "@core/mixins";
  import { momentToDate } from "@core/helpers/momentToDate";
  import OfferOutput from "@/components/Common/OfferOutput";
  import momentjs from "moment";
  import {
    mapState,
    mapActions
  } from "vuex";
  import { formatCurrency, momentInst } from "@core/filters";
  import { OffersFilters } from "@core/mixins";
  import { UPDATE_OFFERS_DICTIONARY } from "@core/store/action-constants";
  import CurrencyCheckbox from "@/components/Common/CurrencyCheckbox";

  export default {
    name: "DashboardChart",
    mixins: [FilteredComponent, OffersFilters],
    components: {
      CurrencyCheckbox,
      FSelect,
      OfferOutput,
      FChartTemplate,
      FDatepicker,
      FCard
    },
    data () {
      const preferredCurrency = this.$store.state.auth.preferredCurrency;
      const {
        dateStart,
        dateEnd,
        offerId,
        currency,
        os,
        geo
      } = this.$route.query;

      return {
        filters: {
          datepicker: {
            dateStart: momentToDate(dateStart
              ? momentInst(dateStart).startOf("day")
              : momentInst().startOf("day").subtract(1, "month")
            ),
            dateEnd: momentToDate(momentInst(dateEnd || undefined).endOf("day"))
          },
          offerId,
          currency: currency || preferredCurrency
          // os,
          // geo
        }
      };
    },
    computed: {
      ...mapState("webmaster/statistic", {
        statistic: ({ statistics }) => {
          const items = statistics?.items;

          return {
            ...statistics,
            items: items ? [...items].reverse() : null
          };
        }
      }),

      isLoading () {
        return this.$wait(`webmaster/statistic/${ GET_STATISTIC }`);
      },

      defaultPeriod () {
        return {
          dateStart: momentToDate(momentInst().startOf("day").subtract(1, "month")),
          dateEnd: momentToDate(momentInst().endOf("day"))
        };
      },

      approve () {
        return this.statistic.items?.map(item => item.conversionApprove);
      },

      xLabels () {
        return this.statistic.items?.map((item) => momentjs(item.day).format("YYYY-MM-DD")) ?? [];
      },

      datasets () {
        if (!this.statistic?.items) return [];

        const { labels } = this.tooltip;

        return [
          {
            name: "transition",
            label: labels.transition,
            color: chartColors.default.danger,
            data: this.statistic.items.map((item) => item.countLandingClicks)
          },
          {
            name: "registration",
            label: labels.registration,
            color: chartColors.default.success,
            data: this.statistic.items.map((item) => item.countLeadsWhite)
          },
          {
            name: "depositAmount",
            label: labels.depositAmount,
            color: chartColors.default.primary,
            data: this.statistic.items.map((item) => item.countLeadsAccepted)
          },
          {
            name: "income",
            label: labels.income,
            color: chartColors.default.warning,
            data: this.statistic.items.map((item) => item.moneyWebmasterAccrued)
          }
        ];
      },

      tooltip () {
        return {
          labels: {
            transition: this.$t("webmaster.dashboard.charts.labels.transition"),
            registration: this.$t("webmaster.dashboard.charts.labels.registration"),
            depositAmount: this.$t("webmaster.dashboard.charts.labels.depositAmount"),
            income: this.$t("webmaster.dashboard.charts.labels.income")
          },
          formatters: {
            transition: (value) => Math.floor(parseFloat(value)),
            registration: (value) => Math.floor(parseFloat(value)),
            depositAmount: (value) => Math.floor(parseFloat(value)),
            income: (value) => formatCurrency(parseFloat(value).toFixed(2), this.$route.query.currency)
          }
        };
      }
    },
    methods: {
      ...mapActions("webmaster/statistic", [
        GET_STATISTIC
      ]),

      ...mapActions("webmaster", {
        UPDATE_OFFERS_DICTIONARY
      }),

      getOffers (offset = 0, label = "") {
        return this.getOffersByActionName(this.UPDATE_OFFERS_DICTIONARY, label, offset);
      },

      async update () {
        const {
          datepicker,
          ...filters
        } = this.filters;

        const params = {
          filters: {
            ...datepicker,
            ...filters
            // os,
            // geo*/
          }
        };

        await this.GET_STATISTIC({ ...params, groups: ["DAY"] });

        const query = {
          ...params.filters,
          dateStart: momentjs(params.filters.dateStart).format("YYYY-MM-DD"),
          dateEnd: momentjs(params.filters.dateEnd).format("YYYY-MM-DD")
        };

        this.$router.push({
          query
        }).catch(_ => {});
      },

      setDateRange (period) {
        this.filters.datepicker = period;
      },

      tooltipFormatter (params) {
        const { labels, formatters } = this.tooltip;
        return tooltipBuilder(params, labels, formatters);
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .dashboard-filters {
            @apply laptop:w-3/4 laptop:justify-end desktop:w-4/5 desktop:flex full-hd:block #{!important};

            .filters {
                @apply laptop:inline-block desktop:flex;
            }
        }
    }
</style>
