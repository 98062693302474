<template>
    <section>
        <Role>
            <template #webmaster>
                <WebmasterDashboard></WebmasterDashboard>
            </template>
            <template #admin>
                <AdminDashboard></AdminDashboard>
            </template>
        </Role>
    </section>
</template>

<script>
  import WebmasterDashboard from "@/components/Webmaster/Dashboard/Dashboard";
  import AdminDashboard from "@/components/Admin/Dashboard/Dashboard";
  import Role from "@/components/Common/Role";

  export default {
    name: "Dashboard",
    components: {
      WebmasterDashboard,
      Role,
      AdminDashboard
    }
  };
</script>
