import { render, staticRenderFns } from "./WebmastersTopCard.vue?vue&type=template&id=071a40ac&scoped=true&"
import script from "./WebmastersTopCard.vue?vue&type=script&lang=js&"
export * from "./WebmastersTopCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071a40ac",
  null
  
)

export default component.exports