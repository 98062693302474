<template>
    <div class="top-card-header">
        <div class="columns custom-header-padding">
            <div class="column">
                <h6 class="title has-text-left is-6 top-card-title">
                    {{ $t(`admin.dashboard.tableCards.headerLabels.total`) }}
                </h6>
                <h4 class="negative-margin subtitle has-text-left is-4 top-card-count">
                    <slot
                        :value="total"
                        name="total">
                        {{ formatEmptyString(total) }}
                    </slot>
                </h4>
            </div>

            <div class="column">
                <h6 class="title has-text-left is-6 top-card-title">
                    {{ $t(`admin.dashboard.tableCards.headerLabels.active`) }}
                </h6>
                <h4 class="negative-margin subtitle has-text-left is-4 top-card-count">
                    <slot
                        :value="active"
                        name="active">
                        {{ active }}
                        <DifferenceIndicator
                            :colorful="false"
                            :value="active">
                        </DifferenceIndicator>
                    </slot>
                </h4>
            </div>
        </div>
        <div class="columns custom-header-padding is-multiline">
            <slot name="filters"></slot>
        </div>
    </div>
</template>

<script>
  import DifferenceIndicator from "@/components/Admin/Dashboard/DifferenceIndicator";
  import { formatEmptyString } from "@core/filters";
  
  export default {
    name: "TopCardHeader",
    components: {
      DifferenceIndicator
    },
    props: {
      total: {
        type: [String, Number],
        default: null
      },

      active: {
        type: [String, Number],
        default: null
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
</style>
