<template>
    <b-table
        :data="data"
        class="common-table">
        <b-table-column
            :key="index"
            v-for="(column, index) in columns"
            :label="getColumnLabel(column.label)"
            centered
            class="nowrap">
            <template
                v-if="column.annotation"
                #header>
                <LabelWithAnnotation
                    class="whitespace-nowrap"
                    :tooltip="column.annotation[activeTabIndex]"
                    :max-width="250"
                    :flip-behavior="['left', 'bottom']"
                    tooltip-position="is-left">
                    {{ getColumnLabel(column.label) }}
                </LabelWithAnnotation>
            </template>
            <template #default="{ row, index }">
                <slot
                    :name="column.field"
                    :row="row">
                    {{ column.field === "order" ? index + 1 : getColumnValue(column, row) }}
                </slot>
            </template>
        </b-table-column>

        <template #empty>
            <div class="hero">
                <div class="hero-body">
                    <h6 class="title has-text-centered is-6">
                        <slot name="empty"></slot>
                    </h6>
                </div>
            </div>
        </template>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </b-table>
</template>

<script>
  import LabelWithAnnotation from "@/components/Common/LabelWithAnnotation";

  export default {
    name: "TopCardTable",
    components: {
      LabelWithAnnotation
    },

    props: {
      columns: {
        type: Array,
        default: null
      },

      data: {
        type: Array,
        default: null
      },

      amountType: {
        type: String,
        default: null
      },

      activeTabIndex: {
        type: Number,
        default: null
      }
    },

    methods: {
      getColumnLabel (label, field) {
        if (typeof label !== "string" && field !== "amount") {
          return label[this.amountType];
        } else {
          return label;
        }
      },

      getValueFormatter (formatter) {
        switch (typeof formatter) {
          case "function":
            return formatter;

          case "object":
            return formatter[this.amountType];
        }
      },

      getColumnValue (column, row) {
        const formatter = this.getValueFormatter(column.formatter);
        const value = row[column.field];

        return formatter ? formatter(value) : value;
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
