<template>
    <FCard
        v-loading="isLoading"
        :title="$t('webmaster.dashboard.news.title')">
        <!--        <template #pagination>-->
        <!--            <FPagination-->
        <!--                :page="1"-->
        <!--                :total="402"-->
        <!--                :per-page="5"-->
        <!--                :page-sizes="[10, 2]"-->
        <!--                layout="prev, slot, next">-->
        <!--                <template #default>-->
        <!--                    <span class="jumper">{{ 1 }} / {{ 5 }}</span>-->
        <!--                </template>-->
        <!--            </FPagination>-->
        <!--        </template>-->
        <template>
            <div
                v-if="newsList.length"
                class="news-list mobile:pb-4">
                <a
                    :key="`${newsItem.date} ${newsItem.title}`"
                    v-for="newsItem in newsList"
                    :href="newsItem.link"
                    target="_blank"
                    :title="newsItem.title"
                    class="news-list__item">
                    <div class="news-list__item-inner">
                        <div class="news-list__item-date">
                            {{ newsItem.date }}
                        </div>
                        <h3 class="news-list__item-title">
                            {{ newsItem.title }}
                        </h3>
                    </div>
                    <div
                        class="news-list__item-dropdown"
                        @click.prevent>
                        <FDropdownOptions></FDropdownOptions>
                    </div>
                </a>
            </div>
            <span
                v-else
                class="flex pb-10">
                {{ $t('webmaster.dashboard.news.empty') }}
            </span>
        </template>

        <!--        <template #footer>-->
        <!--            <FButton-->
        <!--                plain-->
        <!--                class="ml-0 mb-4">-->
        <!--                {{ $t('webmaster.dashboard.news.buttons.allNews') }}-->
        <!--            </FButton>-->
        <!--        </template>-->
    </FCard>
</template>

<script>
  import FCard from "@/components/Common/FCard";
  // import FPagination from "@/components/Common/FPagination";
  // import FButton from "@/components/Common/FButton";
  import FDropdownOptions from "@/components/Common/FDropdownOptions";

  export default {
    name: "DashboardNews",
    components: {
      FDropdownOptions,
      // FButton,
      // FPagination,
      FCard
    },
    props: {
      data: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        newsList: [
        //  {
        //    date: "12:05",
        //    title: "Импликация непредвзято заполняет катарсис",
        //    link: "https://www.google.com/"
        //  },
        //  {
        //    date: "12:05",
        //    title: "Идеи гедонизма занимают центральное место",
        //    link: "https://www.google.com/"
        //  },
        //  {
        //    date: "12:05",
        //    title: "Гравитационный парадокс прост",
        //    link: "https://www.google.com/"
        //  },
        //  {
        //    date: "12:05",
        //    title: "Сомнение философски трансформирует гений",
        //    link: "https://www.google.com/"
        //  }
        ]
      };
    },
    computed: {
      isLoading () {
        return false;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .jumper {
        height: auto !important;
        font-size: 12px !important;
        text-align: center;
        font-weight: normal;
        line-height: 32px !important;
        color: #9AA3B4;
    }
    
    .flex {
        align-items: center;
        justify-content: center;
        
        height: 100%;
        color: #606266;
    }
    
    .news-list {
        &__item {
            @apply py-2 px-4 mb-4;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0 2px 5px rgba(22, 34, 57, 0.08),
                        inset 1px 0 0 #FE2615;
            border-radius: 4px;

            &-inner {
                display: flex;
                align-items: center;

                @media screen and (max-width: 768px) {
                    display: block;
                }
            }

            &-date {
                color: #9AA3B4;
            }

            &-title {
                font-weight: 400;
                @apply mr-4 ml-4;

                @media screen and (max-width: 768px) {
                    @apply mt-3 mr-0 ml-0;
                }
            }

            &-dropdown {
                color: #9AA3B4;
                margin-left: auto;

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
</style>
