<template>
    <div class="dashboard pt-0.5">
        <div>
            <div class="flex flex-wrap">
                <div class="w-full laptop:p-3">
                    <DashboardStatistic class=""></DashboardStatistic>
                </div>
                <!--                <div class="w-full laptop:p-3 laptop:w-1/2">-->
                <!--                    <DashboardNews class=""></DashboardNews>-->
                <!--                </div>-->
            </div>
            <div class="laptop:m-3">
                <DashboardChart></DashboardChart>
            </div>
        </div>
    </div>
</template>

<script>
  import DashboardNews from "@/components/Webmaster/Dashboard/DashboardNews";
  import DashboardStatistic from "@/components/Webmaster/Dashboard/DashboardStatistic";
  import DashboardChart from "@/components/Webmaster/Dashboard/DashboardChart";

  export default {
    name: "Dashboard",
    components: {
      DashboardChart,
      DashboardStatistic
      // DashboardNews
    }
  };
</script>

<style lang="scss" scoped>
    .dashboard {
        &__row {
            @apply flex-wrap;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
