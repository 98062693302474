<template>
    <el-button-group
        v-if="!isLoading"
        class="flex flex-nowrap">
        <el-button
            :key="index"
            v-for="(currency, index) of list"
            :value="currency"
            plain
            :native-value="currency"
            :datatype="type"
            :type="getType(currency)"
            @click="update(currency)">
            <span>{{ $t(`common.currency.${ currency }`) }}</span>
        </el-button>
    </el-button-group>
    <el-main
        v-else
        class="p-px">
        <el-skeleton animated>
            <template #template>
                <el-skeleton-item style="height: 35px;">
                </el-skeleton-item>
            </template>
        </el-skeleton>
    </el-main>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { GET_CURRENCY } from "@core/store/action-constants";

  export default {
    name: "CurrencyCheckbox",
    props: {
      value: {
        type: [String, Array],
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "primary",
        validator: (value) => ["primary", "success", "info", "warning", "danger"].includes(value)
      }
    },

    async created () {
      await this.GET_CURRENCY();
      this.selectedCurrencies = this.value ?? this.list;
    },

    data () {
      return {
        selectedCurrencies: []
      };
    },

    computed: {
      ...mapState("currencyList", {
        list: ({ data }) => data
      }),

      isLoading () {
        return this.$wait(`currencyList/${ GET_CURRENCY }`);
      }
    },

    methods: {
      ...mapActions("currencyList", {
        GET_CURRENCY
      }),

      getType (currency) {
        return this.isSelected(currency) ? this.type : null;
      },

      isSelected (currency) {
        return this.selectedCurrencies.includes(currency);
      },

      update (currency) {
        if (this.multiple) {

          if (typeof this.selectedCurrencies === "string") {
            this.selectedCurrencies = [this.selectedCurrencies];
          }
            
          if (!this.isSelected(currency)) {
            this.selectedCurrencies = [...this.selectedCurrencies, currency];
            this.$emit("input", this.selectedCurrencies);

          } else if (this.selectedCurrencies.length > 1) {
            this.selectedCurrencies = this.selectedCurrencies.filter(item => item !== currency);
            this.$emit("input", this.selectedCurrencies);
          }

        } else if (!this.isSelected(currency)) {
          this.selectedCurrencies = [currency];
          this.$emit("input", currency);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    $types: (
            primary: #5921A0,
            success: #1BD12D,
            info: #9AA3B4,
            warning: #E2C21A,
            danger: #FE2615
    );

    .el-main {
        @apply max-w-min;

        div {
            @apply w-56;
        }
    }

    .el-button {
        @each $type, $color in $types {
            $color-light: transparentize($color, $alpha);

            &.el-button--#{$type} {
                color: $color;
                background: $color-light;
                border-color: $color;
            }

            &[datatype="#{$type}"] {
                &:hover, &:focus {
                    color: $color;
                    border-color: $color;
                }
            }
        }
    }
</style>
