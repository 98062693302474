import { render, staticRenderFns } from "./FDifferenceIndicator.vue?vue&type=template&id=5c824b15&scoped=true&"
import script from "./FDifferenceIndicator.vue?vue&type=script&lang=js&"
export * from "./FDifferenceIndicator.vue?vue&type=script&lang=js&"
import style0 from "./FDifferenceIndicator.vue?vue&type=style&index=0&id=5c824b15&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c824b15",
  null
  
)

export default component.exports