<template>
    <div class="xl:flex xl:items-center justify-end">
        <div class="hidden min-w-xxs periods flex justify-between w-full mr-6 full-hd:mr-3 full-hd:w-2/3 full-hd:flex">
            <el-button
                type="text"
                class="period-btn"
                :class="{ 'active-btn': currentTimestamp === today.timestamp && activeButton === 'day' }"
                @click="setPeriod('day')">
                {{ $t("common.components.datepicker.values.today") }}
            </el-button>
            <el-button
                type="text"
                class="period-btn"
                :class="{ 'active-btn': currentTimestamp === week.timestamp && activeButton === 'week' }"
                @click="setPeriod('week')">
                {{ $t("common.components.datepicker.values.week") }}
            </el-button>
            <el-button
                type="text"
                class="period-btn"
                :class="{ 'active-btn': currentTimestamp === month.timestamp && activeButton === 'month' }"
                @click="setPeriod('month')">
                {{ $t("common.components.datepicker.values.month") }}
            </el-button>
            <el-button
                type="text"
                class="period-btn"
                :class="{ 'active-btn': currentTimestamp === year.timestamp && activeButton === 'year' }"
                @click="setPeriod('year')">
                {{ $t("common.components.datepicker.values.year") }}
            </el-button>
        </div>
        <div
            class="f-datepicker flex justify-end laptop:w-74 full-hd:w-48"
            :class="{ 'has-value': hasValue }">
            <el-dropdown
                class="h-full"
                @command="setPeriod">
                <el-button class="w-26 font-normal full-hd:hidden">
                    <template v-if="currentTimestamp === today.timestamp">
                        <span>{{ $t("common.components.datepicker.values.today") }}</span>
                    </template>
                    <template v-else-if="currentTimestamp === week.timestamp">
                        <span>{{ $t("common.components.datepicker.values.week") }}</span>
                    </template>
                    <template v-else-if="currentTimestamp === month.timestamp">
                        <span>{{ $t("common.components.datepicker.values.month") }}</span>
                    </template>
                    <template v-else-if="currentTimestamp === year.timestamp">
                        <span>{{ $t("common.components.datepicker.values.year") }}</span>
                    </template>
                    <template v-else>
                        <span>{{ $t("common.components.datepicker.values.period") }}</span>
                    </template>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="day">
                        {{ $t("common.components.datepicker.values.today") }}
                    </el-dropdown-item>
                    <el-dropdown-item command="week">
                        {{ $t("common.components.datepicker.values.week") }}
                    </el-dropdown-item>
                    <el-dropdown-item command="month">
                        {{ $t("common.components.datepicker.values.month") }}
                    </el-dropdown-item>
                    <el-dropdown-item command="year">
                        {{ $t("common.components.datepicker.values.year") }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-date-picker
                v-model="_dateStart"
                :picker-options="pickerOptions"
                format="dd.MM"
                popper-class="f-datepicker__popper"
                :placeholder="$t('common.components.datepicker.labels.from')"
                :clearable="false"
                @focus="isOpen = true"
                @blur="isOpen = false">
            </el-date-picker>
            <el-date-picker
                v-model="_dateEnd"
                :picker-options="pickerOptions"
                format="dd.MM"
                popper-class="f-datepicker__popper"
                :placeholder="$t('common.components.datepicker.labels.to')"
                :clearable="false"
                @focus="isOpen = true"
                @blur="isOpen = false">
            </el-date-picker>
        </div>
        <div :class="{ 'fixed w-full h-full bg-gray-modal-bg top-0 left-0 z-40' : isOpen }"></div>
    </div>
</template>

<script>
  import { momentToDate } from "@core/helpers/momentToDate";
  import { momentInst } from "@core/filters";
  import momentjs from "moment";

  export default {
    name: "FDatepicker",
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },

    data () {
      return {
        select: "",
        isOpen: false,
        pickerOptions: {
          firstDayOfWeek: 1
        },
        activeButton: null
      };
    },

    computed: {
      _value: {
        get () {
          return this.value;
        },

        set (value) {
          this.$emit("input", { ...this._value, ...value });
          this.$emit("setPeriod", null);
        }
      },

      _dateStart: {
        get () {
          return this._value.dateStart;
        },

        set (value) {
          this._value = { dateStart: value };
        }
      },

      _dateEnd: {
        get () {
          return this._value.dateEnd;
        },

        set (value) {
          this._value = { dateEnd: momentToDate(momentjs(value).endOf("day")) };
        }
      },

      currentTimestamp () {
        if (this._value) {
          return +this._value.dateStart + +this._value.dateEnd;
        } else {
          return null;
        }
      },

      today () {
        const now = momentInst();
        const start = momentToDate(now.clone().startOf("day"));
        const end = momentToDate(now.clone().endOf("day"));
        const timestamp = +start + +end;
        return { start, end, timestamp };
      },

      week () {
        const now = momentInst();
        const start = momentToDate(now.clone().startOf("week").add(1, "day"));
        const end = momentToDate(now.clone().endOf("day"));
        const timestamp = +start + +end;
        return { start, end, timestamp };
      },

      month () {
        const now = momentInst();
        const start = momentToDate(now.clone().startOf("month"));
        const end = momentToDate(now.clone().endOf("day"));
        const timestamp = +start + +end;
        return { start, end, timestamp };
      },

      year () {
        const now = momentInst();
        const start = momentToDate(now.clone().startOf("year"));
        const end = momentToDate(now.clone().endOf("day"));
        const timestamp = +start + +end;
        return { start, end, timestamp };
      },

      hasValue () {
        const isNotEmptyArray = this.value && Array.isArray(this.value) && this.value.length !== 0;

        return this.value && isNotEmptyArray;
      },

      locale () {
        return this.$root.$i18n.locale;
      }
    },

    methods: {
      setPeriod (value) {
        this.activeButton = value;
        this.$emit("setPeriod", value);

        if (value === "day") {
          this._value.dateStart = this.today.start;
          this._value.dateEnd = this.today.end;
        } else if (value === "week") {
          this._value.dateStart = this.week.start;
          this._value.dateEnd = this.week.end;
        } else if (value === "month") {
          this._value.dateStart = this.month.start;
          this._value.dateEnd = this.month.end;
        } else if (value === "year") {
          this._value.dateStart = this.year.start;
          this._value.dateEnd = this.year.end;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .periods {
        max-width: 200px !important;
    }
    
    .period-btn {
        @apply font-normal text-xs text-gray-text ;
    }
    
    .active-btn {
        @apply text-blue-text;
        border-bottom: 1px solid #5825a0;
    }
    
    .f-datepicker {
        transition: .25s;
        border: 1px solid #dee3ec;
        border-radius: 4px;
        
        ::v-deep {
            .el-date-editor {
                max-width: 103px;
                width: 100%;

                @media screen and (max-width: 768px) {
                    //max-width: 50px;
                }
            }
            .el-dropdown {
                &-selfdefine {
                    border: 0;
                    border-radius: 4px 0 0 4px;
                    height: 40px;
                }
            }
            .el-input--suffix {
                

                .el-input {
                    &__inner {
                        border: 1px solid #FFFFFF;
                        padding-right: 0;
                        border-radius: 0 4px 4px 0;
                        
                        @media screen and (max-width: 768px) {
                            //width: 25px;
                        }
                    }

                    &.is-focus {
                        .el-input__inner {
                            border-color: #E4E7ED;
                        }
                    }
                }
            }
        }
        
        &.has-value {
            ::v-deep {
                .el-range-editor {
                    .el-range__icon {
                        color: #FE2615;
                    }
                }
            }
        }
        
        @media screen and (min-width: 1080px) {
            &:hover {
                box-shadow: 0 5px 5px rgba(22, 34, 57, 0.08);
            }
        }
    }
</style>

<style lang="scss">
    .f-datepicker {
        min-width: 150px;
        &__popper {
            @media screen and (max-width: 1024px) {
                position: fixed !important;
                width: calc(100vw - 40px);
                max-width: 400px;
                top: 25% !important;
                left: 50% !important;
                bottom: auto !important;
                right: auto !important;
                transform: translate3d(-50%, -25%, 0);
                white-space: normal;
                overflow-y: auto;
            }
            
            @media screen and (max-width: 921px) {
                max-width: 400px;
            }
            
            @media screen and (max-width: 411px) {
                max-width: 360px;
            }
            
            .popper__arrow {
                display: none;
            }
            
            &[x-placement^=top] {
                margin-bottom: 4px;
            }
            
            &[x-placement^=bottom] {
                margin-top: 4px;
            }
            
            .el-date-range-picker__header {
                background-color: #9AA3B4;
                border-radius: 4px 4px 0 0;
                padding: 0 10px 0 10px;
                color: white;
            }
            
            .el-date-table td.in-range div {
                background-color: white;
            }
            
            .el-date-table td.end-date span, .el-date-table td.start-date span{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #9AA3B4;
                font-weight: 400;
                border-radius: 4px;
                padding: 14px;
            }
            
            .el-date-table td.today span {
                color: #606266;
                font-weight: 400;
            }
        }
    }
</style>
