<template>
    <DashboardChartTemplate
        :datasets="datasets"
        :is-loading="isLoading"
        :tooltip-formatter="tooltipFormatter"
        :x-labels="xLabels">
    </DashboardChartTemplate>
</template>

<script>
  import { chartColors } from "@/components/Charts/chart.config";
  import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
  import DashboardChartTemplate from "@/components/Common/DashboardChartTemplate";
  import { formatCurrency, formatPercent, moment, toFixed } from "@core/filters";
  import { hasPermissionsStrict } from "@core/mixins/permissions";
  
  export default {
    name: "DashboardChart",

    components: { DashboardChartTemplate },
  
    props: {
      data: {
        type: Object,
        default: null
      },

      filters: {
        type: Object,
        default: null
      },

      userPermissions: {
        type: Array,
        default: () => []
      },
  
      isLoading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      approve () {
        return this.data.items.map(item => item.conversionApprove);
      },

      xLabels () {
        if (!this.data?.items) return [];

        const formats = {
          day: "DD MMM YYYY",
          week: "DD MMM YYYY",
          month: "MMM YYYY"
        };
        const { groups } = this.filters;

        return this.data.items.map((item) => moment(item[groups], formats[groups]));
      },

      datasets () {
        if (!this.data?.items) return [];

        const { labels } = this.tooltip;

        const arr = [
          {
            name: "leads",
            label: labels.leads,
            color: chartColors.default.info,
            data: this.data.items.map((item) => item.countLeadsCreated),
            permissions: ["SUPER.PERMISSION"]
          },
          {
            name: "leadsConfirmed",
            label: labels.leadsConfirmed,
            color: chartColors.default.warning,
            data: this.data.items.map((item) => item.countLeadsAccepted),
            permissions: ["SUPER.PERMISSION"]
          },
          {
            name: "cashFlow",
            label: labels.cashFlow,
            color: chartColors.default.success,
            data: this.data.items.map(item => item.moneyAdvertiserWrittenOff),
            permissions: ["DASHBOARD.CASH_FLOW.GET"]
          },
          {
            name: "commission",
            label: labels.commission,
            color: chartColors.default.light,
            data: this.data.items.map(item => item.moneyCommissions),
            permissions: ["DASHBOARD.COMMISSION.GET"]
          }
        ];

        return arr
          .filter(({ permissions }) => hasPermissionsStrict(permissions, this.userPermissions))
          .map(({ permissions, ...item }) => item);
      },

      tooltip () {
        return {
          labels: {
            leads: this.$t("admin.dashboard.filters.labels.chart.leadsData"),
            leadsConfirmed: this.$t("admin.dashboard.filters.labels.chart.leadsConfirmedData"),
            cashFlow: this.$t("admin.dashboard.filters.labels.chart.cashFlow"),
            commission: this.$t("admin.dashboard.filters.labels.chart.commissionData")
          },
          formatters: {
            leadsConfirmed: (value, index) => {
              const approve = this.approve[index];
              const approvePercent = approve === 0 ? 0 : toFixed(formatPercent(approve), 2);
      
              return `${ value } (${ approvePercent } %)`;
            },
            commission: value => formatCurrency(value, this.$route.query.currency),
            cashFlow: value => formatCurrency(value, this.$route.query.currency)
          }
        };
      }
    },

    methods: {
      tooltipFormatter (params) {
        const { labels, formatters } = this.tooltip;
        return tooltipBuilder(params, labels, formatters);
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
