<template>
    <DashboardTopCard
        type="offers"
        :title="$t(`admin.dashboard.tableCards.offers.labels.title`)"
        :total="totalCount"
        :active-today="activeToday"
        :difference="activeTodayDifference"
        :pagination.sync="offersPagination"
        :amount-type="amountType"
        :table-data="tableData"
        :table-columns="tableColumns"
        :is-loading="isLoading"
        :currency="currency"
        @update-periods="updatePeriods">
        <template #tableEmpty>
            {{ $t("admin.dashboard.tableCards.offers.tables.amount.values.empty") }}
        </template>
        <template #filters>
            <div class="column is-4-tablet is-12-touch">
                <FSelect
                    v-model="amountType"
                    :get-data-vuex="amountTypesList"
                    prop="type"
                    :searchable="false"
                    :clearable="false">
                    <template #text="{ option }">
                        {{ option.name }}
                    </template>
                    <template #selected-option="{ option }">
                        {{ option.name }}
                    </template>
                </FSelect>
            </div>

            <div class="column is-4-tablet is-12-touch">
                <FSelect
                    v-model="currency"
                    :clearable="false"
                    :placeholder="$t(`admin.statistics.statistics.filters.labels.currency`)"
                    :get-data="getCurrency">
                </FSelect>
            </div>

            <div class="column is-4-tablet is-12-touch">
                <FSelect
                    v-model="managerId"
                    field="login"
                    :get-data-vuex="getAdmins"
                    :min-required-length="1"
                    :placeholder="$t(`admin.dashboard.tableCards.offers.tables.amount.filters.manager`)">
                    <template #text="option">
                        <UserOutput :user="option.option"></UserOutput>
                    </template>
                    <template #selected-option="option">
                        <UserOutput :user="option.option"></UserOutput>
                    </template>
                </FSelect>
            </div>
        </template>
    </DashboardTopCard>
</template>

<script>
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { formatCurrency } from "@core/filters";
  import DashboardTopCard from "@/components/Admin/Dashboard/DashboardTops/Common/DashboardTopCard";
  import {
    GET_OFFERS,
    GET_STATISTIC,
    GET_ADMINS_LIST,
    GET_ACTIVE_COUNTS,
    GET_STATISTIC_TOP_CARD_TABLE_DATA,
    GET_CURRENCY
  } from "@core/store/action-constants";
  import {
    UPDATE_TOP_CARD_FILTERS,
    UPDATE_TOP_CARD_PERIODS,
    UPDATE_TOP_CARD_AMOUNT_TYPE,
    UPDATE_ADMINS_FILTERS,
    UPDATE_ADMINS_LIST_PAGINATION,
    UPDATE_PAGINATION, SET_CURRENCY
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "OffersTopCard",
    components: {
      FSelect,
      UserOutput,
      DashboardTopCard
    },

    created () {
      this.GET_OFFERS({
        limit: 1,
        offset: 0
      });
      this.GET_ACTIVE_COUNTS();
      this.SET_CURRENCY();
    },

    computed: {
      ...mapState("admin", {
        totalCount: state => state.offers.data?.count ?? null,
        _offersPagination: (state) => state.dashboard.offersTopCard.pagination,
        currencyList: (state) => state.dashboard.offersTopCard.currencyList.data
      }),

      ...mapFields("admin/dashboard/offersTopCard", {
        fields: [
          "managerId",
          "currency"
        ],
        base: "filters",
        action: UPDATE_TOP_CARD_FILTERS
      }),

      offersPagination: {
        get () {
          return this._offersPagination;
        },

        set (value) {
          this.UPDATE_PAGINATION(value);
        }
      },

      activeToday () {
        return this.$store.state.admin.dashboard.offersTopCard.activeToday ?? 0;
      },

      activeYesterday () {
        return this.$store.state.admin.dashboard.offersTopCard.activeYesterday ?? 0;
      },

      activeTodayDifference () {
        const value = this.activeToday - this.activeYesterday;
        const type = value > 0
          ? "is-success"
          : value < 0
            ? "is-danger"
            : "";

        return {
          wrapper: "b-tag",
          type,
          value
        };
      },

      isLoading () {
        return this.$wait(`admin/offers/${ GET_OFFERS }`)
          || this.$wait(`admin/dashboard/offersTopCard/${ GET_STATISTIC }`);
      },

      tableData () {
        return this.$store.getters[`admin/dashboard/offersTopCard/${ GET_STATISTIC_TOP_CARD_TABLE_DATA }`];
      },

      tableColumns () {
        return [
          {
            label: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.id"),
            field: "order",
            annotation: null
          },
          {
            label: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.name"),
            field: "name",
            annotation: null
          },
          {
            label: {
              leads: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.amount.leads"),
              commission: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.amount.commission")
            },
            formatter: {
              commission: (value) => formatCurrency(value, this.currency)
            },
            field: "amount",
            annotation: null
          },
          {
            label: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.change"),
            field: "change",
            annotation: [
              this.$t("admin.dashboard.tableCards.offers.tables.tooltip.changeToday"),
              this.$t("admin.dashboard.tableCards.offers.tables.tooltip.changeWeek"),
              this.$t("admin.dashboard.tableCards.offers.tables.tooltip.changeMonth")
            ]
          },
          {
            label: this.$t("admin.dashboard.tableCards.webmasters.tables.amount.labels.graph"),
            field: "graph",
            annotation: null
          }
        ];
      },

      amountType: {
        get: function () {
          return this.$store.state.admin.dashboard.offersTopCard.amountType;
        },
        set: function (value) {
          this.$store.dispatch(`admin/dashboard/offersTopCard/${ UPDATE_TOP_CARD_AMOUNT_TYPE }`, value);
        }
      },

      amountTypesList () {
        return () => {
          return {
            count: 2,
            items: [
              {
                type: "leads",
                name: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.amount.leads")
              },
              {
                type: "commission",
                name: this.$t("admin.dashboard.tableCards.offers.tables.amount.labels.amount.commission")
              }
            ]
          };
        };
      },

      adminsList () {
        return this.$store.state.admin.dashboard.offersTopCard.adminsList.data;
      },

      filters () {
        return this.$store.state.admin.dashboard.offersTopCard.filters;
      },

      periods () {
        return this.$store.state.admin.dashboard.offersTopCard.periods;
      }
    },

    methods: {
      ...mapActions("admin", {
        GET_OFFERS: `offers/${ GET_OFFERS }`,
        GET_STATISTIC: `dashboard/offersTopCard/${ GET_STATISTIC }`,
        UPDATE_TOP_CARD_PERIODS: `dashboard/offersTopCard/${ UPDATE_TOP_CARD_PERIODS }`,
        GET_ACTIVE_COUNTS: `dashboard/offersTopCard/${ GET_ACTIVE_COUNTS }`,
        GET_ADMINS_LIST: `dashboard/offersTopCard/adminsList/${ GET_ADMINS_LIST }`,
        UPDATE_PAGINATION: `dashboard/offersTopCard/${ UPDATE_PAGINATION }`,
        GET_CURRENCY: `dashboard/offersTopCard/currencyList/${ GET_CURRENCY }`
      }),

      ...mapMutations("admin/dashboard/offersTopCard/adminsList", {
        UPDATE_ADMINS_FILTERS,
        UPDATE_ADMINS_LIST_PAGINATION
      }),

      ...mapMutations("admin/dashboard/offersTopCard", {
        SET_CURRENCY
      }),

      updatePeriods (payload) {
        this.UPDATE_TOP_CARD_PERIODS(payload);
      },

      async getAdmins (label = "") {
        this.UPDATE_ADMINS_LIST_PAGINATION();
        this.UPDATE_ADMINS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });
        await this.GET_ADMINS_LIST();

        return this.adminsList;
      },
        
      async getCurrency () {
        await this.GET_CURRENCY();
        const items = this.currencyList;
        return {
          items,
          count: items.length
        };
      }
    },

    watch: {
      filters () {
        this.GET_STATISTIC();
      },
      periods () {
        this.GET_STATISTIC();
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
