<template>
    <Card>
        <div class="columns is-multiline">
            <div class="column is-2">
                <b-field :label="$t(`admin.dashboard.filters.labels.offer`)">
                    <FSelect
                        v-model="filters.offerId"
                        :get-data-vuex="getOffers"
                        :min-required-length="1"
                        :placeholder="$t(`admin.dashboard.filters.values.all`)">
                        <template #text="option">
                            <OfferOutput :offer="option.option"></OfferOutput>
                        </template>
                        <template #selected-option="option">
                            <OfferOutput :offer="option.option"></OfferOutput>
                        </template>
                    </FSelect>
                </b-field>
            </div>

            <div class="column is-2">
                <b-field :label="$t(`admin.dashboard.filters.labels.withdrawType`)">
                    <FSelect
                        v-model="filters.withdrawType"
                        :searchable="false"
                        :get-data="getWithdrawTypes"
                        :placeholder="$t(`admin.dashboard.filters.values.all`)"
                        field="label"
                        prop="value">
                    </FSelect>
                </b-field>
            </div>
            <Permissions :permissions="['WEBMASTERS.LIST.ALL', 'WEBMASTERS.LIST.OWN', 'WEBMASTERS.LIST.UNBIND']">
                <div class="column is-2">
                    <b-field :label="$t(`admin.dashboard.filters.labels.webmaster`)">
                        <FSelect
                            v-model="filters.webmasterId"
                            :get-data-vuex="getWebmasters"
                            :min-required-length="1"
                            :placeholder="$t(`admin.dashboard.filters.values.all`)"
                            field="login">
                            <template #text="{ option }">
                                <UserOutput :user="option"></UserOutput>
                            </template>
                            <template #selected-option="{ option }">
                                <UserOutput :user="option"></UserOutput>
                            </template>
                        </FSelect>
                    </b-field>
                </div>
            </Permissions>
            <div class="column is-2">
                <b-field :label="$t(`admin.dashboard.filters.labels.manager`)">
                    <FSelect
                        v-model="filters.managerId"
                        :get-data-vuex="getManagers"
                        :min-required-length="1"
                        :placeholder="$t(`admin.dashboard.filters.values.all`)"
                        field="login">
                        <template #text="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                        <template #selected-option="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                    </FSelect>
                </b-field>
            </div>
            <Permissions :permissions="['ADVERTISERS.LIST.ALL', 'ADVERTISERS.LIST.OWN', 'ADVERTISERS.LIST.UNBIND']">
                <div class="column is-2">
                    <b-field :label="$t(`admin.dashboard.filters.labels.advertiser`)">
                        <FSelect
                            v-model="filters.advertiserId"
                            :get-data-vuex="getAdvertisers"
                            :min-required-length="1"
                            :placeholder="$t(`admin.dashboard.filters.values.all`)"
                            field="login">
                            <template #text="{ option }">
                                <UserOutput :user="option"></UserOutput>
                            </template>
                            <template #selected-option="{ option }">
                                <UserOutput :user="option"></UserOutput>
                            </template>
                        </FSelect>
                    </b-field>
                </div>
            </Permissions>
        </div>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card";
  import OfferOutput from "@/components/Common/OfferOutput";
  import Permissions from "@/components/Common/Permissions";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import { Filters, OffersFilters, UsersFilters } from "@core/mixins";
  import {
    GET_ADMINS_LIST,
    GET_ADVERTISERS_LIST,
    GET_INITIAL_ADMINS,
    GET_INITIAL_ADVERTISERS,
    GET_INITIAL_OFFERS,
    GET_INITIAL_WEBMASTERS,
    GET_OFFERS_LIST,
    GET_WEBMASTERS_LIST,
    GET_WITHDRAW_TYPES
  } from "@core/store/action-constants";
  import {
    UPDATE_ADMINS_FILTERS,
    UPDATE_ADMINS_LIST_PAGINATION,
    UPDATE_ADVERTISERS_FILTERS,
    UPDATE_ADVERTISERS_LIST_PAGINATION,
    UPDATE_OFFERS_FILTERS,
    UPDATE_OFFERS_LIST_PAGINATION,
    UPDATE_WEBMASTERS_FILTERS,
    UPDATE_WEBMASTERS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";

  export default {
    name: "DashboardFiltersOptions",
    mixins: [Filters, UsersFilters, OffersFilters],
    components: { Card, Permissions, FSelect, OfferOutput, UserOutput },

    computed: {
      ...mapState("admin/dashboard", {
        offersList: state => state.offersList.data,
        webmastersList: state => state.webmastersList.data,
        adminsList: state => state.adminsList.data,
        advertisersList: state => state.advertisersList.data,
        withdrawTypesList: state => state.withdrawTypesList.data
      })
    },

    methods: {
      ...mapMutations("admin/dashboard", {
        UPDATE_OFFERS_LIST_PAGINATION: `offersList/${ UPDATE_OFFERS_LIST_PAGINATION }`,
        UPDATE_OFFERS_FILTERS: `offersList/${ UPDATE_OFFERS_FILTERS }`,
        UPDATE_WEBMASTERS_LIST_PAGINATION: `webmastersList/${ UPDATE_WEBMASTERS_LIST_PAGINATION }`,
        UPDATE_WEBMASTERS_FILTERS: `webmastersList/${ UPDATE_WEBMASTERS_FILTERS }`,
        UPDATE_ADMINS_LIST_PAGINATION: `adminsList/${ UPDATE_ADMINS_LIST_PAGINATION }`,
        UPDATE_ADMINS_FILTERS: `adminsList/${ UPDATE_ADMINS_FILTERS }`,
        UPDATE_ADVERTISERS_LIST_PAGINATION: `advertisersList/${ UPDATE_ADVERTISERS_LIST_PAGINATION }`,
        UPDATE_ADVERTISERS_FILTERS: `advertisersList/${ UPDATE_ADVERTISERS_FILTERS }`
      }),

      ...mapActions("admin/dashboard", {
        GET_OFFERS_LIST: `offersList/${ GET_OFFERS_LIST }`,
        GET_INITIAL_OFFERS: `offersList/${ GET_INITIAL_OFFERS }`,
        GET_WEBMASTERS_LIST: `webmastersList/${ GET_WEBMASTERS_LIST }`,
        GET_INITIAL_WEBMASTERS: `webmastersList/${ GET_INITIAL_WEBMASTERS }`,
        GET_ADMINS_LIST: `adminsList/${ GET_ADMINS_LIST }`,
        GET_INITIAL_ADMINS: `adminsList/${ GET_INITIAL_ADMINS }`,
        GET_ADVERTISERS_LIST: `advertisersList/${ GET_ADVERTISERS_LIST }`,
        GET_INITIAL_ADVERTISERS: `advertisersList/${ GET_INITIAL_ADVERTISERS }`,
        GET_WITHDRAW_TYPES: `withdrawTypesList/${ GET_WITHDRAW_TYPES }`
      }),

      async getOffers (label = "") {
        this.UPDATE_OFFERS_LIST_PAGINATION();
        this.UPDATE_OFFERS_FILTERS({ nameOrIntId: label.length > 0 ? label : null });
        if (this.offersList?.items?.length > 0) {
          await this.GET_OFFERS_LIST();
        } else {
          const res = await this.GET_INITIAL_OFFERS(this.$route.query.offerId);
          if (res?.length > 0) {
            this.offer = res[0];
          }
        }
        return this.offersList;
      },

      async getWebmasters (label = "") {
        this.UPDATE_WEBMASTERS_LIST_PAGINATION();
        this.UPDATE_WEBMASTERS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });
        if (this.webmastersList?.items?.length > 0) {
          await this.GET_WEBMASTERS_LIST();
        } else {
          const res = await this.GET_INITIAL_WEBMASTERS(this.$route.query.webmasterId);
          if (res?.length > 0) {
            this.webmasterId = res[0];
          }
        }
        return this.webmastersList;
      },

      async getAdvertisers (label = "") {
        this.UPDATE_ADVERTISERS_LIST_PAGINATION();
        this.UPDATE_ADVERTISERS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });
        if (this.advertisersList?.items?.length > 0) {
          await this.GET_ADVERTISERS_LIST();
        } else {
          const res = await this.GET_INITIAL_ADVERTISERS(this.$route.query.advertiserId);
          if (res?.length > 0) {
            this.advertiserId = res[0];
          }
        }
        return this.advertisersList;
      },

      async getManagers (label = "") {
        this.UPDATE_ADMINS_LIST_PAGINATION();
        this.UPDATE_ADMINS_FILTERS({ loginOrIntId: label.length > 0 ? label : null });
        if (this.adminsList?.items?.length > 0) {
          await this.GET_ADMINS_LIST();
        } else {
          const res = await this.GET_INITIAL_ADMINS(this.$route.query.managerId);
          if (res?.length > 0) {
            this.managerId = res[0];
          }
        }
        return this.adminsList;
      },

      getWithdrawTypes () {
        this.GET_WITHDRAW_TYPES();
        return this.withdrawTypesList;
      }
    }
  };
</script>

<style scoped>

</style>
